<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-dropdown
        id="dropdown-1"
        text="Dodaj dokument"
        variant="primary"
      >
        <b-dropdown-group
          id="dropdown-group-1"
          header="Wystaw fakture"
        >
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/vat">
              Faktura VAT
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/advance-payment">
              Faktura Zaliczkowa
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/vat-margin">
              Faktura marża
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/correction">
              Faktura korekta
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/proforma">
              Faktura proforma
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/accounting-note">
              Nota księgowa
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/client-order">
              Własny dokument nieksięgowy
            </router-link>
          </b-dropdown-item>
        </b-dropdown-group>

        <b-dropdown-group
          id="dropdown-group-2"
          header="Dokumenty kosztowe"
        >
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/cost">
              Dodaj dokument kosztowy
            </router-link>
          </b-dropdown-item>

        </b-dropdown-group>

        <b-dropdown-group
          id="dropdown-group-2"
          header="Dokumenty sprzedażowe"
        >
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/sale">
              Dodaj dokument sprzedażowy
            </router-link>
          </b-dropdown-item>

        </b-dropdown-group>

        <b-dropdown-group
          id="dropdown-group-2"
          header="Wyciągi bankowe"
        >
          <b-dropdown-item>
            <router-link to="/apps/invoice/add/bank-statement">
              Dodaj wyciąg bankowy
            </router-link>
          </b-dropdown-item>

        </b-dropdown-group>

      </b-dropdown>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.sidebar-add-new-client
        variant="primary"
        class="ml-1"
      >
        Dodaj klienta
      </b-button>

      <b-dropdown
        id="dropdown-2"
        text="Dodaj pracownika"
        variant="primary"
        class="ml-1"
      >

        <b-dropdown-item>
          <router-link to="/apps/workers/add">
            Dodaj pracownika
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item>
          <router-link to="/apps/workers/send-form">
            Wyślij kwestionariusz
          </router-link>
        </b-dropdown-item>

      </b-dropdown>

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->

      <notification-dropdown />
      <CompanyDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BDropdown, BDropdownItem, BButton, VBToggle, BDropdownGroup,

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import CompanyDropdown from './components/CompanyDropdown.vue'

export default {
  components: {
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    VBToggle,
    Ripple,
    BDropdownGroup,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    CompanyDropdown,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
