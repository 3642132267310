<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ $store.state.app.activeCompanyName }}
        </p>
      </div>
      <!--      <b-avatar-->
      <!--        size="40"-->
      <!--        :src="userData.avatar"-->
      <!--        variant="light-primary"-->
      <!--        badge-->
      <!--        class="badge-minimal"-->
      <!--        badge-variant="success"-->
      <!--      />-->
    </template>

    <b-dropdown-item
      v-for="(company, idx) in userCompanies"
      :key="idx"
      @click="selectCompany(company.DataBase)"
    >
      {{ company.Business }}
    </b-dropdown-item>
    <b-dropdown-item @click="logout">
      <span>Wyloguj</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>

</template>

<script>
import { BDropdownItem, BNavItemDropdown } from 'bootstrap-vue'
import axios from 'axios'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userCompanies: [],
      activeCompany: this.$store.state.app.activeCompany,
    }
  },
  mounted() {
    this.initCompanies()
  },
  methods: {
    ...mapActions({
      logout: 'app/logout',
    }),
    async initCompanies() {
      this.userCompanies = JSON.parse(localStorage.getItem('userData'))
      // eslint-disable-next-line prefer-destructuring
      this.selectCompany(this.activeCompany ? this.activeCompany : this.userCompanies[0].DataBase)
    },
    selectCompany(nip) {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.post(`${process.env.VUE_APP_API_URL}SelectDataBase`, {
        DataBase: nip,
      }, config).then(response => {
        localStorage.setItem('userRole', response.data.User.Type)
        // eslint-disable-next-line prefer-destructuring
        this.$store.commit('app/UPDATE_ACTIVE_COMPANY', this.userCompanies.filter(company => company.DataBase === nip)[0].DataBase)
        this.$store.commit('app/UPDATE_ACTIVE_COMPANY_NAME', this.userCompanies.filter(company => company.DataBase === nip)[0].Business)
        if (response.data.User.Type === 'Administrator-BR') {
          this.getClients()
        }
        this.getCompanyData()
      }).catch(error => {
        console.log(error)
        if (error.response.status === 401) {
          this.logout()
        }
      })
    },
    getClients() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Client`, config).then(response => {
        this.$store.commit('app/UPDATE_TAX_OFFICE_CLIENTS', response.data)
      }).catch(error => {
        console.log(error)
        Swal.fire('Nie można pobrać danych klientów', '', 'error')
      })
    },
    getCompanyData() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Business`, config).then(response => {
        this.$store.commit('app/UPDATE_ACTIVE_COMPANY_BUSINESS_DATA', response.data.filter(row => row.NIP === this.$store.state.app.activeCompany)[0])
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
  },
}
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: auto !important;
}
</style>
